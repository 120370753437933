@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply bg-black text-white py-1 px-4 rounded-md;
  }
}

.blobText {
  fill: black;
  font-family: sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
}
